import React, {Fragment} from "react";
import {BrowserRouter as Router, Route} from "react-router-dom";

import List from "./List";
import Myungo from "./History01";
import Estate from "./History02";
import Origin from "./History03";
import San from "./History04";



function History({match}) {
    
    return (
        <Fragment>
            <Router>
                <Route exact path={`${match.url}`} component={List} />
                <Route path={`${match.url}/myungo`} component={Myungo} />
                <Route path={`${match.url}/estate`} component={Estate} />
                <Route path={`${match.url}/origin`} component={Origin} />
                <Route path={`${match.url}/san`} component={San} />
            </Router>
        </Fragment>
    )
}

export default History;
