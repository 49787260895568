import React, {Fragment} from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import oldmap from '../../images/oldmap.png';
import survey from '../../images/survey.png';
import pub from '../../images/pub.png';


function History ({history}) {
    
    const handleList = () => {
        history.push("/history");
    }
    
    return (
        <Fragment>
            <Paper elevation={0} style={{marginTop: "20px", marginLeft: "5px", marginRight: "5px", padding: "10px", maxWidth: "800px"}}>
                <Typography variant="h6" component="p">
                    선산 와현리(풍계리) 산47의 기원
                </Typography>
                <Typography variant="body1" component="p">
                    현재 수평종친회 소유의 부동산은 와현리 산47(현재는 와현리 58번지) 일대로 행정구역으로는 여러 지번으로 나누어져 있다. 
                    면적은 202,514㎡(약 61,260평)이다. 
                    <br/><br/>
                    이 선산의 기원에 대해서는 자세히 알려진 바는 없지만 명고(鳴皐) 송필만이 1918년부터 단독 소유한 임야라는 것이 일제 강점기에 작성된 
                    기록물을 통해 입증된다. 송필만이 풍계리에 살면서 자신의 조부와 백부 묘가 있다는 것을 근거로 임야의 연고자로 등록되면서 그것을 취득한 것으로 알려져 있다. 
                    <br/><br/>
                    일제 강점기에 작성된 임야원도를 보면 당시 장호원 지역(청미면 풍계리)은 청주한씨(淸州韓氏)의 소유의 땅이 많았는데 일본이 발행한 관보에도 
                    이천에 청주한씨종중이 있었음을 알 수 있다. 물론 다른 성씨 소유의 땅들도 많았고 규형계 27세손인 송필대, 송필영, 송필만 소유의 땅도 포함되어 있었다. 
                    송필만이 일찍 모친을 잃고 큰아버지댁에서 자랐다는 전언에 근거하면 이들은 모두 송원구(宋源龜)의 후손들이고 그 지역에 살았을 것이다.   
                    <br/><br/>                 
                </Typography>
                <Typography variant="h6" component="p">
                    일제의 토지 수탈과 삼림령
                </Typography>
                <Typography variant="body1" component="p">
                   일제 강점기 일본은 조선의 토지와 산림자원을 수탈하기 위해 조선인들이 소유한 부동산을 조사하기 시작했다. 국유지로 편입하거나 땅 소유자들에게 
                   세금을 부과하고 지세수입을 증대하려는 목적이었는데 이것이 바로 일본의 ‘토지조사사업(土地調査事業)’이다. 
                   <br/><br/>
                   1911년 일본은 근대적 소유관계를 확정한다는 명목으로 국유지였던 조선의 산림을 개인 소유로 전환시킨 ‘삼림령’을 제정하고 조림대부제도를 설정해 
                   일본인이 직접 조선의 산림을 점유하게 하여 산림 수탈의 법적 근거로 삼았다. 1918년에는 임야의 소유자와 그 경계 심사를 위해 ‘조선임야조사령’을 공포해 
                   신고제에 입각한 임야조사를 시행하고 이를 따르지 않으면 모두 국유지로 편입시킨다.
                   <br/><br/>
                   명고 송필만은 1908년 장학사(獎學社) 측량견습소(測量見習所) 도근과(圖根科)에서 도근측량, 산술, 제도 과정을 이수하였기 때문에 직접 선산을 측량하여 
                   신고한다. 하지만 임야조사서 기록에 의하면 선산의 소유자는 국유로 표시되어 있고 연고자는 송필만으로 되어 있다. 아마 기준에 부합하지 않아 소유자 대신 
                   연고자만이 인정된 것으로 추정된다. 
                   <br/><br/>             
                </Typography>
                <Typography variant="h6" component="p">
                    조선 총독부 관보
                </Typography>
                <Typography variant="body1" component="p">
                    송필만이 산47의 소유자라는 기록은 조선총독부가 발행한 관보에 비로소 나타난다. 당시 일본은 국토의 보안, 위해의 방지, 수원(水源)의 함양, 
                    항행의 목표, 풍치를 위하여 필요하다고 인정되면 삼림을 ‘보안림’에 편입할 수 있다는 법령을 만들었는데, 이 법령에 의하면 보안림에 편입하는 
                    지역을 조선총독부 관보에 고시하고 보안림 편입 조서에는 소재지, 면적, 지황(地況), 임황(林況), 등과 함께 원래 소유자를 기재하도록 했다. 
                    <br/><br/>
                    1939년 10월 13일에 발행된 관보(정규호 3822)에 실린 보안림 고시에 의하면 풍계리 산47에 대한 소유자로 송필만이 기재되어 있다. 이것은 아마도 1926년 4월 조선총독부가 
                    특별연고자가 있는 국유산림을 무상양도 처분하는 ‘조선특별연고삼림양여령(朝鮮特別緣故森林讓與令)’을 공포했는데 이때 연고자 송필만의 소유권이 
                    회복되었을 것으로 추정한다. 즉 보안림으로 편입되는 시점에는 이미 송필만이 그 땅의 소유자임이 확인된 것이다.
                    <br/><br/>
                    1945년 해방이 되고 대한민국 정부가 1958년 민법을 제정하면서 민법 부칙 제10조 제1항에 따라 1960년 1월 1일부터 1965년 12월 31일까지 부동산에 관한 
                    소유권 변동 내역을 등기하는 절차가 진행되었는데, 송필만은 앞서 기술한 실체적인 소유권 기록을 근거로 1968년 10월 21일 최초 소유권보존등기를 
                    경료할 수 있었다. 전언에 의하면 등기 기한이 지났지만 당시 법무부장관 이호(李澔)의 도움으로 성사될 수 있었다고 한다. 
                    이것이 가능했던 것은 송필만이 제헌국회의원이었고 4.19혁명 이후에는 참의원으로 당선된 원로정치인이었기 때문이 아니었을까 생각된다.
                    <br/><br/>
                    하지만 이 과정에서 와현리와 풍계리에서 집성촌을 이루고 살던 청주한씨(淸州韓氏)들과 토지분쟁에 휘말렸는데 아마도 오랫동안 무주공산이었던 선산의 일부를 
                    한씨들이 점유했을 것으로 추측된다. 송필만은 청주한씨종중과 협상하고, 송형달(宋衡達)과 송선겸(宋善謙)으로 하여금 경작자 86인에게 각 200평씩 토지를 보상하고 
                    대신 그들의 보증인 서명을 받아 등기를 완료함으로써 선산에 대한 소유권 취득을 마치게 되었다고 전해진다.
                </Typography>
                <img src={oldmap} width="100%" style={{marginTop: "10px"}}/>
                <img src={survey} width="100%" style={{marginTop: "5px"}}/>
                <img src={pub} width="100%" style={{marginTop: "5px"}}/>
                <br/><br/>
                <Button color="primary" variant="outlined" onClick={handleList}>list</Button>
            </Paper>
            
        </Fragment>
    )
    
}

export default History;
