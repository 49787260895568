import React, {Fragment} from 'react';
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";


function History ({history}) {
    
    const handleList = () => {
        history.push("/history");
    }
    
    return (
        <Fragment>
            <Paper elevation={0} style={{marginTop: "20px", marginLeft: "5px", marginRight: "5px", padding: "10px", maxWidth: "800px"}}>
                <Typography variant="h6" component="p">
                    명고(鳴皐)와 수평종친회
                </Typography>
                <Typography variant="body1" component="p">
                    명고(鳴皐)는 종친 송필만(宋必滿)의 아호(雅號)이다. 명고 어르신은 1887년 장호원 수평말에서 태어났다.
                    빈곤한 가정에서 3세 때 어머니를 여의고 큰 댁에서 자랐다. 청운의 꿈을 안고 일본과 미국에서 유학하면서
                    이승만 박사와 함께 독립운동을 했고 제헌국회의원으로써 대한민국 격동의 역사 속에서 고군분투했다.
                    <br/><br/>
                    귀국하여 고향에 돌아와 보니 당신 부모님 산소가 타인 또는 공동묘지에 방치되는 것을 죄송스럽게 여기고
                    장호원 수평말 인근의 임야를 매입하여 조상을 모시는 선산을 조성하였다. 또 선산 관리를 위한 재실(묘막)을 짓기 위하여
                    텃골 이권형으로부터 가옥을 매입하고 이것을 "상효재(尙孝齎)"라는 이름으로 서예가 일중 김충현의 글씨로
                    현판을 만들어 걸었다. 현재 상효재는 <b>"명고 기념관"</b>으로 명칭을 변경, 명고 어르신의 유품들을 보관 전시하고 있다.
                    <br/><br/>
                    명고 어르신은 1978년 임종 전에 선산의 일부(약 6만여평)를 종친들에게 나누어 주었고 이후 <b>여산송씨정가공파 덕표후손(규운, 규형, 규문) 수평종친회</b> 명의로 지금까지
                    이어져 오고 있다. 2007년부터 선산을 골프장 사업자인 블랙스톤에게 임대하면서 흩어져 있던 묘들을 모아 "수평묘원"을
                    새로 조성하게 되었다.
                    <br/><br/>
                    수평종친회는 그동안 송종헌, 송선우, 송선문, 송현달, 송순달, 송선원, 송준 회장들과 여러 회원들의 자발적인 참여와 봉사를 통해 명고 송필만 선생이
                    물려준 소중한 자산과 그 뜻을 지키기 위해 노력하고 있다.
                    <br/><br/>
                    
                </Typography>
                <Button color="primary" variant="outlined" onClick={handleList}>list</Button>
            </Paper>
            
        </Fragment>
    )
    
}

export default History;
